/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:bc8c5ee8-ccb1-43ce-816a-aae1d232e3fb",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_NSjqKraFh",
    "aws_user_pools_web_client_id": "2938tu7sun1p0h1roca1ji12ku",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ozcowqyegvd75g67p2pfg5l3yq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-azcvutxr6ja3bkgq5rlgdqdieu",
    "aws_user_files_s3_bucket": "s3testapp23127-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
